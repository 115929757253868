import axiosWithToken from "./request";
import axios from "axios";

const login = async (email: string, password: string, token: string) => {
  try {
    const is2FAEnabled = process.env.REACT_APP_ENABLE_2FA == "true";
    const url = is2FAEnabled ? "auth/sign-in" : "auth/sign-in/development";
    const response = await axiosWithToken.post(
      url,
      { email, password },
      { headers: { recaptcha: token } }
    );

    const userTokens = JSON.stringify(response.data);
    localStorage.setItem("tokens", userTokens);

    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return { error: "Server error" };
    }
  }
};

const twoFactorLogin = async (authenticatorToken: string) => {
  try {
    const response = await axiosWithToken.post("auth/two-factor", { authenticatorToken });

    const userTokens = JSON.stringify(response.data);
    localStorage.setItem("tokens", userTokens);

    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return { error: "Token invalid" };
    }
  }
};

const getTwoFactorData = async () => {
  try {
    const response = await axiosWithToken.get("auth/two-factor");
    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return { error: "Server error" };
    }
  }
};

const setTwoFactor = async (secret: string, token: string) => {
  const { accessToken } = JSON.parse(localStorage.getItem("tokens"));
  try {
    return await axios.put(`${process.env.REACT_APP_SERVER_URL}api/auth/two-factor`, { secret, token }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
  } catch (error) {
    if (error.response) {
      throw new Error('Invalid token');
    }
  }
};

const refreshTokens = async () => {
  const { refreshToken } = JSON.parse(localStorage.getItem("tokens"));
  if (!refreshToken) return;
  try {
    const response = await axiosWithToken.post(`auth/refresh-token`, {
      refresh_token: refreshToken,
    });
    const userTokens = JSON.stringify(response.data);
    localStorage.setItem("tokens", userTokens);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    localStorage.removeItem("tokens");
    return error.response.data;
  }
};

const logout = async () => {
  const { refreshToken } = JSON.parse(localStorage.getItem("tokens"));
  localStorage.removeItem("tokens");
  await axiosWithToken.delete("auth/logout", { data: { refresh_token: refreshToken } });
};

export const resetPassword = async (email: string) => {
  try {
    const response = await axiosWithToken.put("auth/password", { email });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const verifyPasswordToken = async (token: string) => {
  try {
    const response = await axiosWithToken.post("auth/password", { token });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const authService = {
  login,
  twoFactorLogin,
  getTwoFactorData,
  setTwoFactor,
  refreshTokens,
  logout,
  resetPassword,
  verifyPasswordToken,
};
